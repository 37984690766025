<template>
  <div>
    <v-container fluid class="py-6">
        <v-row class="px-4">
            <v-col lg="6">
                <v-card class="card-shadow border-radius-xl mt-6" id="basic">
                    <div class="px-6 py-6">
                        <h5 class="text-h5 font-weight-bold text-typo">Basic Info</h5>
                    </div>
                    <div class="px-6 pb-6 pt-0">
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                    v-model="donor.status"
                                    :items="statuses"
                                    label="Status"
                                    dense
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    color="#e91e63"
                                    label="Price bn"
                                    placeholder="Price bn"
                                    class="font-size-input input-style"
                                    v-model="donor.price_bn"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    color="#e91e63"
                                    label="Price an"
                                    placeholder="Price an"
                                    class="font-size-input input-style"
                                    v-model="donor.price_an"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    color="#e91e63"
                                    label="Example of published article"
                                    placeholder="Example of published article"
                                    class="font-size-input input-style"
                                    v-model="donor.example_of_published_article"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="donor.linkbuilder_user_id"
                                    :items="linkbuilders"
                                    label="Linkbuilder"
                                    dense
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-menu
                                    ref="showLatestCollaborationDatePicker"
                                    v-model="showLatestCollaborationDatePicker"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="donor.latest_collaboration_date"
                                            label="Latest collaboration date"
                                            persistent-hint
                                            prepend-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="donor.latest_collaboration_date"
                                        no-title
                                        @input="showLatestCollaborationDatePicker = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6">
                                <v-btn
                                    color="#cb0c9f"
                                    class="
                                font-weight-bolder
                                btn-default
                                bg-gradient-default
                                py-4
                                px-8
                                ms-auto
                                mt-sm-auto mt-4
                              "
                                    small
                                    @click="updateDonorItem()"
                                >
                                    Update Donor Item
                                </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn
                                    class="
                                      transparent
                                      py-4
                                      px-8
                                      ms-auto
                                      mt-sm-auto mt-4
                                    "
                                    small
                                    @click="close()"
                                >
                                    Close
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>
<script>
import ExtendedRecord from './components/ExtendedRecord'
import axiosIns from '../../../plugins/axios';

export default {
  name: "DonorItem",
  components: {
    ExtendedRecord,
  },
  data: () => {
      return {
          showLatestCollaborationDatePicker: false,
          statuses: [
              {text: 'New', value: 'New'},
              {text: 'Processing', value: 'Processing'},
              {text: 'Expensive', value: 'Expensive'},
              {text: 'Outreach later', value: 'Outreach later'},
              {text: 'Filtered out', value: 'Filtered out'},
              {text: 'Didn\'t respond', value: 'Didn\'t respond'},
              {text: 'Successful collaboration', value: 'Successful collaboration'},
          ],
          linkbuilders: [],
          donor: {
              status: '',
              price_bn: '',
              price_an: '',
              notes: '',
              linkbuilder_user_id: '',
              example_of_published_article: '',
              latest_collaboration_date: '',
              added_at: '',
          },
      };
  },
  computed: {},
  beforeMount () {
      this.loadDonorItem();
      this.loadLinkbuilders();
  },
  methods: {
      close() {
          this.$router.push('/donors')
      },
      async loadDonorItem() {
          const response = await axiosIns.get('/api/donor-domains/'+this.$route.params.id);
          if (response && response.status === 200) {
              this.donor = response.data.donor;
          }
      },
      async loadLinkbuilders() {
          const response = await axiosIns.get('/api/users');
          if (response && response.status === 200) {
              for(let item of response.data.users.data) {
                  this.linkbuilders.push({
                      value: item.id,
                      text: item.name,
                  });
              }
              console.log(this.linkbuilders);
          }
      },
      evaluateContactColor(contact) {
          if (contact.status === 'new') {
              return 'gray';
          } else if (contact.status === 'active') {
              return 'green';
          } else if (contact.status === 'never_reply') {
              return 'red';
          } else if (contact.status === 'waiting_for_replay') {
              return 'secondary';
          } else {
              return 'green';
          }
      },
      evaluateContactIcon(contact) {
          if (contact.type === 'email') {
              return 'fas fa-at';
          } else if (contact.type === 'twitter') {
              return 'fab fa-twitter';
          } else if (contact.type === 'linkedin') {
              return 'fab fa-linkedin';
          } else if (contact.type === 'contact_form') {
              return 'fas fa-desktop';
          } else {
              return 'fas fa-link';
          }
      },
      putContactIntoClipboard(contact) {
          navigator.clipboard.writeText(contact.value);
      },
      putEmailIntoClipboard(email) {
          navigator.clipboard.writeText(email);
      },
      evaluateStatusColor(status) {
          if (status === 'new') {
              return 'gray';
          } else if (status === 'Successful collaboration') {
              return 'green';
          } else {
              return 'red';
          }
      },
      evaluateStatusIcon(status) {
          // item.status == 'Successful collaboration' ? 'fas fa-check': 'fas fa-search'
          if (status === 'new') {
              return 'fas fa-search';
          } else if (status === 'Successful collaboration') {
              return 'fas fa-check';
          } else {
              return 'fas fa-times';
          }
      },
      evaluateStatusBorderClass(status) {
          // item.status == 'Successful collaboration'
          //     ? 'border-success'
          //     : 'border-danger'
          if (status === 'new') {
              return 'border-default';
          } else if (status === 'Successful collaboration') {
              return 'border-success';
          } else {
              return 'border-danger';
          }
      },
      updateDonorItem() {
          axiosIns.post('/api/donor-domains/'+this.$route.params.id, this.donor)
              .then((response) => {
                  console.log(response);
                  this.$router.push('/donors');
              })
              .catch((error) => {
                  this.$store.commit('app/SNACKBAR', {
                      show: true,
                      body: error.response.data.message,
                      type: 'error',
                  });
              });
      },
  }
};
</script>
