<template>
    <v-container fluid class="py-6 green-bg">
        <v-row class="px-4">
            <v-col lg="3">
                <v-card class="border-radius-xl card-shadow">
                    <div class="px-6 py-6 text-center">
                        <h2 class="text-gradient text-primary font-weight-bolder text-h2">
                            <span @click="callModalToEditField('price_bn')">{{donorRecord.price_bn || '--'}}$</span>
                        </h2>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                            Before negotiations
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Price</p>
                    </div>
                </v-card>
            </v-col>
            <v-col lg="3">
                <v-card class="border-radius-xl card-shadow">
                    <div class="px-6 py-6 text-center">
                        <h2 class="green--text font-weight-bolder text-h2">
                            <span @click="callModalToEditField('price_an')">{{donorRecord.price_an || '--'}}$</span>
                        </h2>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                            After negotiations
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Price</p>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="px-4">
            <v-col lg="2">
                <v-card class="border-radius-xl card-shadow">
                    <div class="px-6 py-6 text-center">
                        <h6 class="black--text font-weight-bolder text-h6">
                            <span @click="callModalToEditField('example_of_published_article')">
                                <template v-if="donorRecord.example_of_published_article">
                                    <v-btn
                                        :href="donorRecord.example_of_published_article"
                                        target="_blank"
                                        elevation="2"
                                        x-small
                                    >
                                          <v-icon left x-small>
                                              fas fa-link
                                          </v-icon>
                                        link
                                    </v-btn>
                                </template>
                                <template v-else>
                                    <span>--</span>
                                </template>
                            </span>
                        </h6>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                            Example of published article
                        </h6>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="px-4">
            <v-col lg="8">
                <v-card class="card-shadow border-radius-xl mt-6" id="contacts">
                    <div class="px-6 py-6">
                        <h5 class="text-h5 font-weight-bold text-typo">Contacts</h5>
                    </div>
                    <div class="px-6 pb-6 pt-0">
                        <v-btn @click="callModalCreateContact(donorRecord)">Create contact</v-btn>
                        <v-data-table :headers="contactHeaders" :items="donorRecord.contacts" hide-default-footer>
                            <template v-slot:item.value="{ item }">
                                <span @click="callModalToEditContactField('value', item)">{{ item.value || '--' }}</span>
                            </template>
                            <template v-slot:item.position="{ item }">
                                <span @click="callModalToEditContactField('position', item)">{{ item.position || '--' }}</span>
                            </template>
                            <template v-slot:item.first_name="{ item }">
                                <span @click="callModalToEditContactField('first_name', item)">{{ item.first_name || '--' }}</span>
                            </template>
                            <template v-slot:item.status="{ item }">
                                <span @click="callModalToEditContactField('status', item)">{{ prepareStatusValue(item.status) }}</span>
                            </template>
                            <template v-slot:item.type="{ item }">
                                <span @click="callModalToEditContactField('type', item)">{{ prepareTypesValue(item.type) }}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-btn
                                    @click="deleteContact(item)"
                                    icon
                                    elevation="0"
                                    :ripple="false"
                                    height="28"
                                    min-width="36"
                                    width="36"
                                    class="btn-ls me-2 my-2 rounded-sm"
                                    color="#67748e"
                                >
                                    <v-icon size="14" class="material-icons-round">
                                        fas fa-trash-alt
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="px-4">
            <v-col lg="9">
                <v-card class="card-shadow border-radius-xl mt-6" id="relatedCompetitors">
                    <div class="px-6 py-6">
                        <h5 class="text-h5 font-weight-bold text-typo">Basic Info</h5>
                    </div>
                    <div class="px-6 pb-6 pt-0">
                        <p>The domain {{ donorRecord.domain }} was added at {{ donorRecord.added_at }}</p>
                        <template v-if="donorRecord.competitors">
                            <p></p>
                            <p>This donor is related to the following competitors:</p>
                            <ul>
                                <li v-for="competitor in donorRecord.competitors">
                                    <v-btn x-small :href="competitor.domain" target="_blank" >
                                        <v-icon left x-small>
                                            fas fa-link
                                        </v-icon>
                                        {{ competitor.domain }}
                                    </v-btn>
                                    by
                                    <v-btn x-small :href="competitor.pivot.referring_page_url" target="_blank" >
                                        <v-icon left x-small>
                                            fas fa-link
                                        </v-icon>
                                        {{ competitor.pivot.referring_page_url }}
                                    </v-btn>
                                </li>
                            </ul>
                            <p></p>
                        </template>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axiosIns from '../../../../plugins/axios';

export default {
  name: "ExtendedRecord",
  components: {},
  props: [
      'donorRecord'
  ],
  data: () => {
      return {
          contactHeaders: [
              {
                  text: "Type",
                  sortable: false,
                  value: "type",
                  class: "text-secondary font-weight-bolder opacity-7 border-bottom",
              },
              {
                  text: "Value",
                  sortable: false,
                  value: "value",
                  class: "text-secondary font-weight-bolder opacity-7 border-bottom",
              },
              {
                  text: "Status",
                  sortable: false,
                  value: "status",
                  class: "text-secondary font-weight-bolder opacity-7 border-bottom",
              },
              {
                  text: "Name",
                  sortable: false,
                  value: "first_name",
                  class: "text-secondary font-weight-bolder opacity-7 border-bottom",
              },
              {
                  text: "Position",
                  sortable: false,
                  value: "position",
                  class: "text-secondary font-weight-bolder opacity-7 border-bottom",
              },
              {
                  text: "First name",
                  sortable: false,
                  value: "first_name",
                  class: "text-secondary font-weight-bolder opacity-7 border-bottom",
              },
              {
                  text: "Actions",
                  sortable: false,
                  value: "actions",
                  class: "text-secondary font-weight-bolder opacity-7 border-bottom",
              },
          ],
      };
  },
  computed: {},
  methods: {
      prepareStatusValue(machineName) {
          const list = {
              'new': 'New',
              active: 'Active',
              never_reply: 'Never reply',
              waiting_for_replay: 'Waiting for replay',
          };
          return list[machineName];
      },
      prepareTypesValue(machineName) {
          const list = {
              email: 'Email',
              twitter: 'Twitter',
              linkedin: 'Linkedin',
              contact_form: 'Contact form',
          };
          return list[machineName];
      },
      deleteContact(contactItem) {
          console.log(contactItem);
          if (window.confirm("Do you really want to delete this donor contact?")) {
              axiosIns.delete('/api/donor-contacts/'+contactItem.id)
              .then((response) => {
                  this.$router.go(this.$router.currentRoute);
              })
              .catch((error) => {
                  this.$store.commit('app/SNACKBAR', {
                      show: true,
                      body: error.response.data.message,
                      type: 'error',
                  });
              });
          }
      },
      callModalToEditContactField(fieldName, contact) {
          if (fieldName === 'value' || fieldName === 'position' || fieldName === 'first_name') {
              this.$store.commit('app/SHOW_EDIT_MODAL', {
                  show: true,
                  title: fieldName,
                  type: 'text-field',
                  field: {
                      entityType: 'DonorContact',
                      entityId: contact.id,
                      type: 'string',
                      field: fieldName,
                      value: contact[fieldName],
                  },
                  onSuccess: (entity) => {
                      this.donorRecord.contacts.forEach((item, index) => {
                          if (item.id === entity.id) {
                              this.donorRecord.contacts[index][fieldName] = entity[fieldName];
                          }
                      });
                  },
              });
          } else if (fieldName === 'type' || fieldName === 'status') {
              let items = (fieldName === 'type')
                  ? [
                      {text: 'Email', value: 'email'},
                      {text: 'Twitter', value: 'twitter'},
                      {text: 'LinkedIn', value: 'linkedin'},
                      {text: 'Contact form', value: 'contact_form'},
                  ]
                  : [
                      {text: 'New', value: 'new'},
                      {text: 'Active', value: 'active'},
                      {text: 'Never reply', value: 'never_reply'},
                      {text: 'Waiting for replay', value: 'waiting_for_replay'},
                  ];
              this.$store.commit('app/SHOW_EDIT_MODAL', {
                  show: true,
                  title: 'Edit ' + fieldName,
                  type: 'selectSingle',
                  options: {
                      items: items,
                      text: 'text',
                      value: 'value'
                  },
                  field: {
                      entityType: 'DonorContact',
                      entityId: contact.id,
                      type: 'string',
                      field: fieldName,
                      value: contact[fieldName],
                  },
                  onSuccess: (entity) => {
                      console.log(entity);
                      this.donorRecord.contacts.forEach((item, index) => {
                          if (item.id === entity.id) {
                              this.donorRecord.contacts[index][fieldName] = entity[fieldName];
                          }
                      });
                  },
              });
          }
      },
      callModalToEditField(fieldName) {
          if (fieldName === 'price_bn' || fieldName === 'price_an' || fieldName === 'example_of_published_article') {
              this.$store.commit('app/SHOW_EDIT_MODAL', {
                  show: true,
                  title: fieldName,
                  type: 'text-field',
                  field: {
                      entityType: 'DonorDomain',
                      entityId: this.donorRecord.id,
                      type: 'string',
                      field: fieldName,
                      value: this.donorRecord[fieldName],
                  },
                  onSuccess: (entity) => {
                      this.donorRecord[fieldName] = entity[fieldName];
                  },
              });
          } else if (fieldName === 'status') {
              let items = ['New', 'Processing', 'Expensive', 'Successful collaboration', 'Outreach later', 'Filtered out', 'Didn\'t respond'];
              let field = (fieldName === 'linkbuilder') ? 'linkbuilder_user_id' : 'status';
              this.$store.commit('app/SHOW_EDIT_MODAL', {
                  show: true,
                  title: fieldName,
                  type: 'selectSingle',
                  options: {
                      items: items,
                      text: '',
                      value: ''
                  },
                  field: {
                      entityType: 'DonorDomain',
                      entityId: this.donorRecord.id,
                      type: 'string',
                      field: field,
                      value: this.donorRecord[field],
                  },
                  onSuccess: (entity) => {
                      if (fieldName === 'linkbuilder') {
                          this.donorRecord.linkbuilder = entity['linkbuilder'];
                      } else {
                          this.donorRecord.status = entity['status'];
                      }
                  },
              });
          } else if (fieldName === 'linkbuilder') {
              let items = this.$store.getters['other/linkbuilders'];
              let field = 'linkbuilder_user_id';
              this.$store.commit('app/SHOW_EDIT_MODAL', {
                  show: true,
                  title: fieldName,
                  type: 'selectSingle',
                  options: {
                      items: items,
                      text: 'name',
                      value: 'id'
                  },
                  field: {
                      entityType: 'DonorDomain',
                      entityId: this.donorRecord.id,
                      type: 'string',
                      field: field,
                      value: this.donorRecord[field],
                  },
                  onSuccess: (entity) => {
                      if (fieldName === 'linkbuilder') {
                          this.donorRecord.linkbuilder = entity['linkbuilder'];
                      } else {
                          this.donorRecord.status = entity['status'];
                      }
                  },
              });
          }
      },
      evaluateContactColor(contact) {
          if (contact.status === 'new') {
              return 'gray';
          } else if (contact.status === 'active') {
              return 'green';
          } else if (contact.status === 'never_reply') {
              return 'red';
          } else if (contact.status === 'waiting_for_replay') {
              return 'secondary';
          } else {
              return 'green';
          }
      },
      evaluateContactIcon(contact) {
          if (contact.type === 'email') {
              return 'fas fa-at';
          } else if (contact.type === 'twitter') {
              return 'fab fa-twitter';
          } else if (contact.type === 'linkedin') {
              return 'fab fa-linkedin';
          } else if (contact.type === 'contact_form') {
              return 'fas fa-desktop';
          } else {
              return 'fas fa-link';
          }
      },
      putContactIntoClipboard(contact) {
          navigator.clipboard.writeText(contact.value);
      },
      putEmailIntoClipboard(email) {
          navigator.clipboard.writeText(email);
      },
      evaluateStatusColor(status) {
          if (status === 'new') {
              return 'gray';
          } else if (status === 'Successful collaboration') {
              return 'green';
          } else {
              return 'red';
          }
      },
      evaluateStatusIcon(status) {
          // item.status == 'Successful collaboration' ? 'fas fa-check': 'fas fa-search'
          if (status === 'new') {
              return 'fas fa-search';
          } else if (status === 'Successful collaboration') {
              return 'fas fa-check';
          } else {
              return 'fas fa-times';
          }
      },
      evaluateStatusBorderClass(status) {
          // item.status == 'Successful collaboration'
          //     ? 'border-success'
          //     : 'border-danger'
          if (status === 'new') {
              return 'border-default';
          } else if (status === 'Successful collaboration') {
              return 'border-success';
          } else {
              return 'border-danger';
          }
      },
      callModalCreateContact()
      {
          this.$store.state.other.createContactModal.form.donor_domain_id = this.donorRecord.id;
          this.$store.state.other.createContactModal.onSuccess = (donorContact) => {
              this.donorRecord.contacts.push(donorContact);
          };
          this.$store.commit('other/SHOW_CONTACT_CREATE_MODAL');
      }
  },
};
</script>
<style>
.green-bg {
    background: #eff2f5;
}
</style>
